import React, { useEffect, useState } from "react";
import {
  FaCalendarAlt,
  FaPlus,
  FaRegCopy,
  FaCheck,
  FaClock,
  FaVideo,
} from "react-icons/fa";
import "./App.css";
import { dataSendToAndroid } from "./DataSendtoAndroid&Ios";
import { baseUrl, meeting_url } from "./baseUrl";

const App = () => {
  const token = "your-auth-token"; // Replace with a valid token
  const apiUrl = `${baseUrl}/conference_list`; // Replace with your API URL
  const apiUrl_add = `${baseUrl}/add_conference`; // Replace with your API URL
  // const apiUrl = "http://localhost:3030/conference_list"; // Replace with your API URL
  // const apiUrl_add = "https://localhost:3030/add_conference"; // Replace with your API URL
  const meeting_urls = meeting_url;

  const [conferences, setConferences] = useState([]);
  const [userName, setUserName] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newConference, setNewConference] = useState({
    name: "",
    startDate: "",
    duration: 1,
  });
  const [copiedUrls, setCopiedUrls] = useState({}); // State to track copied URLs

  const handleOpenModal = () => {
    console.log("Open Modal clicked");
    setIsModalOpen(true);
  };
  const handleIdPass = (id) => {
    console.log("Id Pass", id);
    dataSendToAndroid(id);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    // Get the query string from the URL (e.g., ?user_name=123)
    const queryParams = new URLSearchParams(window.location.search);

    // Get the value of the 'user_name' parameter
    const userName = queryParams.get("user_name");
    if (userName) {
      fetchConferences(userName);

      setUserName(userName);
    }
    // Set the state with the user_name value
  }, []);
  const fetchConferences = async (userName) => {
    try {
      const response = await fetch(`${apiUrl}/${userName}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      });

      const data = await response.json();
      if (data.statusCode === 200) {
        // const data = await response.json(); // Read the JSON response
        console.log("Fetched Conferences:", data);
        setConferences(data.response.detail);
      } else {
        console.error("Failed to fetch conferences");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleAddConference = async () => {
    const newEntry = {
      user_id: userName,
      conference_name: newConference.name,
      conference_date: newConference.startDate,
      conference_duration: newConference.duration,
    };

    try {
      const response = await fetch(apiUrl_add, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
        body: JSON.stringify(newEntry),
      });
      // console.log('res=====',response.statusCode);
      const data = await response.json();
      if (data.statusCode === 200) {
        // setConferences([...conferences, addedConference]);
        setNewConference({ name: "", startDate: "", duration: "" });
        handleCloseModal();
        fetchConferences(userName);
      } else {
        console.error("Failed to add conference");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Function to handle copy
  const handleCopy = (url, conferenceId) => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        // Update the copiedUrls state to show that this URL has been copied
        setCopiedUrls((prevState) => ({
          ...prevState,
          [conferenceId]: true, // Set the copied state for this conference ID
        }));

        // Reset copied state after a timeout
        setTimeout(() => {
          setCopiedUrls((prevState) => ({
            ...prevState,
            [conferenceId]: false, // Reset the copied state after 2 seconds
          }));
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <div className="app">
      <h1 className="title">Conference List</h1>

      {/* <FaPlus className="add-icon" onClick={() => setIsModalOpen(true)} /> */}
      <div className="add-icon-container" title="Add Conference">
        {/* <FaPlus className="add-icon" onClick={() => handleOpenModal()} /> */}
        <div className="modal-buttons">
          <button className="save-button" onClick={() => handleOpenModal()}>
            Adhoc Conference
          </button>
          <button
            className="save-button"
            onClick={() => handleIdPass("groupcall")}
          >
            Group Call
          </button>
        </div>{" "}
      </div>

      <div className="conference-container">
        {conferences.length > 0 ? (
          conferences.map((conf, index) => (
            <div key={index} className="conference-card">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span className="conference-title">{conf.conference_name}</span>
                <div className="icon-buttons">
                  {/* Join Icon */}
                  {/* <FaVideo className="join-call-icon" title="Join Call" onClick={() => window.open(meeting_url + conf.conference_uid, "_blank")} /> */}

                  <div
                    className="copy-container"
                    onClick={() =>
                      handleCopy(
                        meeting_urls + conf.conference_uid,
                        conf.conference_uid
                      )
                    }
                    title={
                      copiedUrls[conf.conference_uid] ? "Copied!" : "Copy link"
                    }
                  >
                    {copiedUrls[conf.conference_uid] ? (
                      <FaCheck className="copied-icon" />
                    ) : (
                      <FaRegCopy className="copy-icon" />
                    )}
                  </div>
                  {/* <button className="join-button" onClick={() => window.open(meeting_url + conf.conference_uid, "_blank")}>
        Join
      </button> */}
                  <FaVideo
                    size={20}
                    className="join-icon"
                    title="Join Conference"
                    style={{ cursor: "pointer" }}
                    // onClick={() => window.open(meeting_url + conf.conference_uid, "_blank")}
                    onClick={() => handleIdPass(conf.conference_uid)}
                  />

                  {/* Copy Icon */}
                  {/* <FaRegCopy
                size={20}
                className="copy-icon"
                title="Copy URL"
                onClick={() => handleCopyUrl(meeting_url + conf.conference_uid)}
              /> */}
                </div>
              </div>
              {/* <p className="conference-location">{conf.location}</p> */}
              <div className="conference-details">
                <div className="detail-item">
                  <FaClock className="icon" />
                  <span>Duration: {conf.conference_duration}</span>
                </div>
                <div className="detail-item">
                  <FaCalendarAlt className="icon" />
                  <span>{conf.conference_date}</span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p style={{ textAlign: "center" }}>No conference available</p>
        )}
      </div>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h2>Add New Conference</h2>
            <input
              type="text"
              placeholder="Conference Name"
              value={newConference.name}
              onChange={(e) =>
                setNewConference({ ...newConference, name: e.target.value })
              }
            />
            <input
              type="datetime-local"
              value={newConference.startDate}
              onChange={(e) =>
                setNewConference({
                  ...newConference,
                  startDate: e.target.value,
                })
              }
            />
            <select
              value={newConference.duration}
              onChange={(e) =>
                setNewConference({ ...newConference, duration: e.target.value })
              }
            >
              <option value="" disabled>
                Select Duration
              </option>
              <option value="1">1 Hour</option>
              <option value="2">2 Hours</option>
              <option value="3">3 Hours</option>
              <option value="4">4 Hours</option>
              <option value="5">5 Hours</option>
            </select>

            <div className="modal-buttons">
              <button className="save-button" onClick={handleAddConference}>
                Save
              </button>
              <button
                className="cancel-button"
                onClick={() => handleCloseModal()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
