export const dataSendToAndroid = (data) => {
    console.log(
      "Sending data to Android/iOS and other React app:",
      JSON.stringify(data)
    );
    if (window.androidObj) {
      if (data === "groupcall") {
        window.androidObj.groupCall(JSON.stringify(data));
      } else {
        window.androidObj.readId(JSON.stringify(data));
      }
    }
    if (data === "groupcall") {
      if (window.webkit && window.webkit.messageHandlers.groupCall) {
        window.webkit.messageHandlers.groupCall.postMessage(JSON.stringify(data));
      }
    } else {
      if (window.webkit && window.webkit.messageHandlers.readId) {
        window.webkit.messageHandlers.readId.postMessage(JSON.stringify(data));
      }
    }
  
    window.parent.postMessage(
      { type: "dataReceivedFromReact", payload: JSON.stringify(data) },
      "*"
    );
  };
  